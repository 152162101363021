import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];

  constructor(public router: Router) {}
  name: any;
  ngOnInit() {
    const token = localStorage.getItem('token');
    const UserTypeId = localStorage.getItem('UserTypeId');

    this.name = localStorage.getItem('Name');

    if (token) {
      if (UserTypeId == '1') {
        this.appPages = [
          { title: 'Dashboard', url: 'dashboard', icon: 'home' },
          { title: 'Ticket', url: 'ticket', icon: 'ticket' },
          { title: 'Customer', url: 'customer', icon: 'person' },
          { title: 'Technician', url: 'technician', icon: 'people' },
          { title: 'Subagent', url: 'subagent', icon: 'person' },
          { title: 'Analytics', url: 'analytics', icon: 'analytics' },
          { title: 'Estimate', url: 'estimate', icon: 'card' },
          { title: 'calendar', url: 'calendar', icon: 'calendar' },
          // { title: 'Subscription', url: 'subscription', icon: 'card' },
          { title: 'Setting', url: 'setting', icon: 'settings' },
        ];
      }
      if (UserTypeId == '2') {
        this.appPages = [
          { title: 'Dashboard', url: 'dashboard', icon: 'home' },
          { title: 'Ticket', url: 'ticket', icon: 'ticket' },
          { title: 'Setting', url: 'setting', icon: 'settings' },
          { title: 'Estimate', url: 'item', icon: 'card' },
        ];
      }
      if (UserTypeId == '3') {
        this.appPages = [
          { title: 'Dashboard', url: 'dashboard', icon: 'home' },
          { title: 'Ticket', url: 'ticket', icon: 'ticket' },
          { title: 'Customer', url: 'customer', icon: 'person' },
          { title: 'Technician', url: 'technician', icon: 'people' },
          { title: 'Analytics', url: 'analytics', icon: 'analytics' },
          { title: 'calendar', url: 'calendar', icon: 'calendar' },
          { title: 'Setting', url: 'setting', icon: 'settings' },
        ];
      }
      if (UserTypeId == '4') {
        this.appPages = [
          { title: 'Dashboard', url: 'dashboard', icon: 'home' },
          { title: 'Ticket', url: 'ticket', icon: 'ticket' },
          { title: 'Setting', url: 'setting', icon: 'settings' },
        ];
      }
      // this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/login']);
    }
  }
  logout() {
    localStorage.clear();
    console.log(localStorage.getItem('token'));

    console.log('clear');
    this.appPages = [];
    window.location.href = '/login';
    // this.router.navigate(['/login']);
  }
}
