import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: 'details/:id',
    loadChildren: () =>
      import('./report-detail/report-detail.module').then(
        (m) => m.ReportDetailPageModule
      ),
  },
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'add-ticket',
    loadChildren: () =>
      import('./add-ticket/add-ticket.module').then(
        (m) => m.AddTicketPageModule
      ),
  },
  {
    path: 'add-customer',
    loadChildren: () =>
      import('./add-customer/add-customer.module').then(
        (m) => m.AddCustomerPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'add-subagent',
    loadChildren: () =>
      import('./add-subagent/add-subagent.module').then(
        (m) => m.AddSubagentPageModule
      ),
  },
  {
    path: 'add-technician',
    loadChildren: () =>
      import('./add-technician/add-technician.module').then(
        (m) => m.AddTechnicianPageModule
      ),
  },
  {
    path: 'ticket',
    loadChildren: () =>
      import('./ticket/ticket.module').then((m) => m.TicketPageModule),
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./customer/customer.module').then((m) => m.CustomerPageModule),
  },
  {
    path: 'technician',
    loadChildren: () =>
      import('./technician/technician.module').then(
        (m) => m.TechnicianPageModule
      ),
  },
  {
    path: 'subagent',
    loadChildren: () =>
      import('./subagent/subagent.module').then((m) => m.SubagentPageModule),
  },

  {
    path: 'subscription',
    loadChildren: () =>
      import('./subscription/subscription.module').then(
        (m) => m.SubscriptionPageModule
      ),
  },
  {
    path: 'ticket-list',
    loadChildren: () =>
      import('./ticket-list/ticket-list.module').then(
        (m) => m.TicketListPageModule
      ),
  },
  {
    path: 'customer-list',
    loadChildren: () =>
      import('./customer-list/customer-list.module').then(
        (m) => m.CustomerListPageModule
      ),
  },
  {
    path: 'technician-list',
    loadChildren: () =>
      import('./technician-list/technician-list.module').then(
        (m) => m.TechnicianListPageModule
      ),
  },
  {
    path: 'sub-agent-list',
    loadChildren: () =>
      import('./sub-agent-list/sub-agent-list.module').then(
        (m) => m.SubAgentListPageModule
      ),
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./analytics/analytics.module').then((m) => m.AnalyticsPageModule),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./calendar/calendar.module').then((m) => m.CalendarPageModule),
  },
  {
    path: 'technicianapp',
    loadChildren: () =>
      import('./technician-app/technician-app.module').then(
        (m) => m.TechnicianAppPageModule
      ),
  },
  {
    path: 'jobdetail',
    loadChildren: () =>
      import('./jobdetail/jobdetail.module').then((m) => m.JobdetailPageModule),
  },
  {
    path: 'assign-ticket',
    loadChildren: () =>
      import('./assign-ticket/assign-ticket.module').then(
        (m) => m.AssignTicketPageModule
      ),
  },
  {
    path: 'technician-login',
    loadChildren: () =>
      import('./technician-login/technician-login.module').then(
        (m) => m.TechnicianLoginPageModule
      ),
  },
  {
    path: 'report-detail',
    loadChildren: () =>
      import('./report-detail/report-detail.module').then(
        (m) => m.ReportDetailPageModule
      ),
  },
  {
    path: 'estimate',
    loadChildren: () =>
      import('./estimate/estimate.module').then((m) => m.EstimatePageModule),
  },
  {
    path: 'add-estimate',
    loadChildren: () =>
      import('./add-estimate/add-estimate.module').then(
        (m) => m.AddEstimatePageModule
      ),
  },
  {
    path: 'sitevisitestimate-list',
    loadChildren: () =>
      import('./sitevisitestimate-list/sitevisitestimate-list.module').then(
        (m) => m.SitevisitestimateListPageModule
      ),
  },
  {
    path: 'invoice/:id',
    loadChildren: () =>
      import('./invoice/invoice.module').then((m) => m.InvoicePageModule),
  },
  {
    path: 'item',
    loadChildren: () =>
      import('./item/item.module').then((m) => m.ItemPageModule),
  },
  {
    path: 'add-item',
    loadChildren: () =>
      import('./add-item/add-item.module').then((m) => m.AddItemPageModule),
  },
  {
    path: 'approve/:token',
    loadChildren: () =>
      import('./approve/approve.module').then((m) => m.ApprovePageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
